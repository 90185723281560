import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import css from './404.module.scss';
import PageNotFound404Icon from "../images/404/404.svg";
import {Link} from "gatsby";
import {getLink, getRoutes} from "../components/shared/routes";
import MainButton, {mainButtonArrow, mainButtonFill} from "../components/shared/MainButton/MainButton";

class Page404 extends React.Component {
    render() {
        return this.createLayout();
    }

    createLayout() {
        const pageInternalName = '404';
        return (
            <Layout page={pageInternalName}>
                <SEO/>
                {this.createPageContent()}
            </Layout>
        );
    }

    createPageContent() {
        return (
            <div className={css.pageContent}>
                {this.createPageNotFound404Icon()}
                {this.createPageDescription()}
                {this.createPageBackButton()}
            </div>
        )
    }

    createPageNotFound404Icon() {
        return (
            <div className={css.notFound404IconWrapper}>
                <img className={css.notFound404Icon} src={PageNotFound404Icon} alt="" />
            </div>
        )
    }

    createPageDescription() {
        return (
            <div>
                <div className={css.pageDescription}>
                 Oooops! Sorry The Page Not Found :(
                </div>
            </div>
        )
    }

    createPageBackButton() {
        return (
            <div>
                {this.createBackToHomeLink()}
            </div>
        )
    }

    createBackToHomeLink() {
        return (
           <Link to={getLink([getRoutes().home])}>
               <MainButton label={"Return to Homepage"} arrow={mainButtonArrow.left} fill={mainButtonFill.gradient}/>
           </Link>
        )
    }
}

export default Page404;

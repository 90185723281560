import React from 'react';
import Page404 from "./404";

class Page500 extends React.Component {
    render() {
        return <Page404 />;
    }
}

export default Page500;
